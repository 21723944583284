import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import Button from "CustomButtons/Button.jsx";
import $ from 'jquery';

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import modalStyle from "assets/jss/material-kit-react/modalStyle.jsx";
import Close from "@material-ui/icons/Close";
import CustomInput from "CustomInput/CustomInput.jsx";


const testActionStyles = {
  ...modalStyle,
};


function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class TestActions extends React.Component {

	constructor(props){
	super(props);
	this.state = {
		modal: false,
		firstQuestion: "",
		lastQuestion: "",
		fileToImport: "",
		fileToImportName: "",
		testImportStatus: "",
		testImportErrors: "",
		keyModal: false,
		testKeyImportStatus: ""
	};
}

		handleViewTest = (e) =>{
			e.preventDefault();
			var testId = e.currentTarget.name;
		    $.ajax({
			    type: 'GET',
			    url: getBaseURL() + '/is-test-scheduled.json?test_info_id='+ testId,
		    }).done((data, status, jqXHR)=>{
		    	this.props.storeStateCallback();
		    	this.props.history.push('/test_questions/new?test_id=' + testId +'&mode=view');
		    }).fail((jqXHR, textStatus, errorThrown) => {
		    	this.props.errorMessageCallback({message: "Error occured when trying to view test due to " + jqXHR["responseText"]});
		    })
		}

		handleEditTest = (e) =>{
			e.preventDefault();
			var testId = e.currentTarget.name;
		    $.ajax({
			    type: 'GET',
			    url: getBaseURL() + '/is-test-scheduled.json?test_info_id='+ testId,
		    }).done((data, status, jqXHR)=>{
		    	this.props.storeStateCallback();
		    	if(data){
        			this.props.history.push('/test_questions/new?mode=scheduled&test_id=' + testId);
      			} else{
		    		this.props.history.push('/test_questions/new?test_id=' + testId); 
		    	}
		    }).fail((jqXHR, textStatus, errorThrown) => {
		    	this.props.errorMessageCallback({message: "Error occured when trying to edit test due to " + jqXHR["responseText"]});
		    })
		}

		
		handleArchiveTest = (e) => {
			var testId = e.currentTarget.name;
			var confirm_flag = confirm('Are you sure?')
		    if(!confirm_flag){  
		      e.preventDefault();
		      return;
		    }
		    $.ajax({
			    type: 'POST',
			    url: getBaseURL() + '/remove-test',
			    data: {'test_info_id': testId},
		    }).done((data, status, jqXHR)=>{
		    	//Re-execute search
		    	this.props.handleTestAdvSearch(e);
		    }).fail((jqXHR, textStatus, errorThrown) => {
		    	this.props.errorMessageCallback({message: "Error occured when trying to remove test due to " + jqXHR["responseText"]});
		    })

		}

		handleDuplicateTest = (e) => {
			var testId = e.currentTarget.name;
			this.props.storeStateCallback();
			this.props.history.push('/test_infos/new?test_id=' + testId);
		}

		handleOpenModal = (e) =>{
			this.setState({modal: true})
		}

		handleClose(modal) {
	    	this.setState({modal: false});
	  	}

	  	handleOpenKeyModal = (e) =>{
			this.setState({keyModal: true})
		}

		handleKeyModalClose(modal) {
	    	this.setState({keyModal: false});
	  	}

	  	handleInput = (e) => {
	  	  e.preventDefault();
	  	  var id = e.target.id;
	  	  var obj ={};
	  	  obj[id] = e.target.value;
	  	  this.setState(obj);
	  	}

	  	handleImportTest = (e) => {
	  		const testId = e.currentTarget.name;
	  		const firstQuestion = this.state.firstQuestion
	  		const lastQuestion = this.state.lastQuestion
	  		var firstQuestionParam =""
	  		var lastQuestionParam = ""

		    if(firstQuestion != ""){
	  			firstQuestionParam = "&firstQ="+firstQuestion
	  		}
	  		if(lastQuestion != ""){
	  			lastQuestionParam = "&lastQ="+lastQuestion
	  		}
		    var url =  getBaseURL() + "/crossport-test?testId="+testId+"&op=import"+firstQuestionParam+lastQuestionParam;
		    var formData = new FormData();
		    formData.append('file', this.state.fileToImport);
		    
	  		$.ajax({
  		      type: 'POST',
  		      url: url,
  		      data: formData,
  		      cache: false,
  		      contentType: false,
  		      processData: false
  		    }).done((data, status, jqXHR)=>{
  		      this.setState({
  		        testImportStatus: 'SUCCESS',
  		      });
  		    }).fail((jqXHR, textStatus, errorThrown) => {
  		      this.setState({
  		        testImportStatus: 'FAILED',
  		      });
  		    })
	  	}

	  	handleImportKey = (e) => {
	  		const testId = e.currentTarget.name;
		    var file = this.state.fileToImport;
		    if(!file){
	  			alert('Please upload a valid file')
	  			return;
	  		}
		    var url =  getBaseURL() + "/import-key?testId="+testId;
		    var formData = new FormData();
		    formData.append('file', file);
		    
	  		$.ajax({
  		      type: 'POST',
  		      url: url,
  		      data: formData,
  		      cache: false,
  		      contentType: false,
  		      processData: false
  		    }).done((data, status, jqXHR)=>{
  		      this.setState({
  		        testKeyImportStatus: 'SUCCESS',
  		      });
  		    }).fail((jqXHR, textStatus, errorThrown) => {
  		      this.setState({
  		        testKeyImportStatus: 'FAILED',
  		      });
  		    })

	  	}

	  	handleExportTest = (e) => {
	  		const testId = e.currentTarget.name;
	  		const firstQuestion = this.state.firstQuestion
	  		const lastQuestion = this.state.lastQuestion
	  		var firstQuestionParam =""
	  		var lastQuestionParam = ""
	  		if(firstQuestion != ""){
	  			firstQuestionParam = "&firstQ="+firstQuestion
	  		}
	  		if(lastQuestion != ""){
	  			lastQuestionParam = "&lastQ="+lastQuestion
	  		}
	  		window.location.href = getBaseURL() + "/crossport-test?testId="+testId+"&op=export"+firstQuestionParam+lastQuestionParam	
	  	}

	  	handleFileInput = (e) => {
		    e.preventDefault();
		    var fileToImportName = e.currentTarget.files[0].name;
		    var fileToImport = e.currentTarget.files[0];
		    this.setState({fileToImportName,fileToImport});
		}
		
		render () {
			const{classes,name,errorMessageCallback,handleTestAdvSearch,testStatus,exportImportPrivilege} = this.props;
			const {fileToImportName,testImportStatus, testImportErrors, testKeyImportStatus} = this.state;
			const searchButtonStyle = {
			  	textTransform: "none",
			    backgroundColor: "#3f51b5",
			    color: "white",
			    borderRadius: "3px",
			    padding: "0.2rem 0.75rem",
			    "&:hover,&:focus": {
			      color: "white",
			      background: "#57849b",
			    },
			}
			return (
				<React.Fragment>
		            	<Button
		            	  name={name}    
			              size="sm"
			              onClick={this.handleViewTest}
			              style={searchButtonStyle}>
			              View
			            </Button>
		            	<Button    
		            	  name={name}
			              size="sm"
			              onClick={this.handleEditTest}
			              style={searchButtonStyle}>
			              Edit
			            </Button>
			            <Button   
			              name={name} 
			              size="sm"
			              onClick={this.handleDuplicateTest}
			              style={searchButtonStyle}>
			              Duplicate
			            </Button>
			            {testStatus != 'ARCHIVED' && 
			            <Button   
			              name={name} 
			              size="sm"
			              onClick={this.handleArchiveTest}
			              style={searchButtonStyle}>
			              Archive
			            </Button>
			            }
			            {exportImportPrivilege && testStatus == "COMPLETED" &&
			            <React.Fragment>
			            <Button   
			              name={name} 
			              size="sm"
			              onClick={this.handleOpenModal}
			              style={searchButtonStyle}>
			              Export
			            </Button>
			            <Dialog
				          classes={{	
				            root: classes.center,
				            paper: classes.modal
				          }}
				          open={this.state.modal}
				          TransitionComponent={Transition}
				          keepMounted
				          onClose={() => this.handleClose("modal")}
				          aria-labelledby="modal-slide-title"
				          aria-describedby="modal-slide-description">
				          <DialogTitle
				            id="classic-modal-slide-title"
				            disableTypography
				            className={classes.modalHeader}>
				            <IconButton
				              className={classes.modalCloseButton}
				              key="close"
				              aria-label="Close"
				              color="inherit"
				              onClick={() => this.handleClose("modal")}>
				              <Close className={classes.modalClose} />
				            </IconButton>
				            <h4 className={classes.modalTitle}>Export Test</h4>
				          </DialogTitle>
				          <DialogContent
				            id="modal-slide-description"
				            className={classes.modalBody}>
				          
	                      <p>Please note that this operation is not reversible and might lead to data loss</p>
				          <div style={{width: "50%"}}>
				          <CustomInput
				            labelText="First Question"
				            id="firstQuestion"
				            formControlProps={{
				              fullWidth: true
				            }}
				            inputProps={{
				              type: "number",
				              value: this.state.firstQuestion,
				              onChange: this.handleInput,
				            }}   
				          />
				          </div>
				          <div style={{width: "50%"}}>
				          <CustomInput
				            labelText="Last Question"
				            id="lastQuestion"
				            formControlProps={{
				              fullWidth: true
				            }}
				            inputProps={{
				              type: "number",
				              value: this.state.lastQuestion,
				              onChange: this.handleInput,
				            }}   
				          />
				          </div>
				          </DialogContent>
				          <DialogActions
				            className={classes.modalFooter +" " +classes.modalFooterCenter}>
				            <Button
				            name={name}
				              onClick={this.handleExportTest}
				              color="success">
				              Export Test
				            </Button>
				          </DialogActions>
				        </Dialog>
				        </React.Fragment>
			            }
			            {exportImportPrivilege && testStatus == "INCOMPLETE" &&
			            <React.Fragment>
			            <Button   
			              name={name} 
			              size="sm"
			              onClick={this.handleOpenModal}
			              style={searchButtonStyle}>
			              Import
			            </Button>
			            <Dialog
				          classes={{	
				            root: classes.center,
				            paper: classes.modal
				          }}
				          open={this.state.modal}
				          TransitionComponent={Transition}
				          keepMounted
				          onClose={() => this.handleClose("modal")}
				          aria-labelledby="modal-slide-title"
				          aria-describedby="modal-slide-description">
				          <DialogTitle
				            id="classic-modal-slide-title"
				            disableTypography
				            className={classes.modalHeader}>
				            <IconButton
				              className={classes.modalCloseButton}
				              key="close"
				              aria-label="Close"
				              color="inherit"
				              onClick={() => this.handleClose("modal")}>
				              <Close className={classes.modalClose} />
				            </IconButton>
				            <h4 className={classes.modalTitle}>Import Test</h4>
				          </DialogTitle>
				          <DialogContent
				            id="modal-slide-description"
				            className={classes.modalBody}>
				            {testImportStatus &&
			          			<p style={testImportStatus == "SUCCESS" ? 
			          				{width: "fit-content",fontSize: "16px", borderRadius: "5px", color: "white", backgroundColor: "#4caf50", padding: "5px"}
			          				: {width: "fit-content",fontSize: "16px", borderRadius: "5px", color: "white", backgroundColor: "#F44336", padding: "5px"}} >{testImportStatus}</p>
			          		}
			              	<p>Please note that this operation is not reversible and might lead to data loss</p>
				          	<div style={{width: "50%"}}>
				          	<CustomInput
				          	  labelText="First Question"
				          	  id="firstQuestion"
				          	  formControlProps={{
				          	    fullWidth: true
				          	  }}
				          	  inputProps={{
				          	    type: "number",
				          	    value: this.state.firstQuestion,
				          	    onChange: this.handleInput,
				          	  }}   
				          	/>
				          	</div>
				          	<div style={{width: "50%"}}>
				          	<CustomInput
				          	  labelText="Last Question"
				          	  id="lastQuestion"
				          	  formControlProps={{
				          	    fullWidth: true
				          	  }}
				          	  inputProps={{
				          	    type: "number",
				          	    value: this.state.lastQuestion,
				          	    onChange: this.handleInput,
				          	  }}   
				          	/>
				          	</div>
				          	<div>
				          		<input
				          		  accept=".csv"
				          		  className={classes.input}
				          		  style={{ display: 'none' }}
				          		  id="raised-button-file"
				          		  multiple
				          		  type="file"
				          		  onChange={this.handleFileInput}
				          		/>
				          		<label htmlFor="raised-button-file">
				          		  <Button component="span" style={{
				          		    textTransform: "none",
				          		    backgroundColor: "#607D8B",
				          		    padding: "8px 20px",
				          		    margin: "0px"
				          		  }}>
				          		    Browse
				          		  </Button>
				          		</label> 
				          		{fileToImportName &&
				          		<p style={{fontSize: "12px", color: "rgb(34, 56, 69)"}} >{fileToImportName}</p>
				          		}
				          	</div>
				          </DialogContent>
				          <DialogActions
				            className={classes.modalFooter +" " +classes.modalFooterCenter}>
				          	{!testImportStatus &&  
				         	<Button
				              name={name}
				              onClick={this.handleImportTest}
				              color="success">
				              Import Test
				            </Button>
				          	}
				          </DialogActions>
				        </Dialog>
				        </React.Fragment>
			            }
			            {exportImportPrivilege && testStatus == "INCOMPLETE" &&
			            <React.Fragment>
			            <Button   
			              name={name} 
			              size="sm"
			              onClick={this.handleOpenKeyModal}
			              style={searchButtonStyle}>
			              Import Key
			            </Button>
			            <Dialog
				          classes={{	
				            root: classes.center,
				            paper: classes.modal
				          }}
				          open={this.state.keyModal}
				          TransitionComponent={Transition}
				          keepMounted
				          onClose={() => this.handleKeyModalClose("modal")}
				          aria-labelledby="modal-slide-title"
				          aria-describedby="modal-slide-description">
				          <DialogTitle
				            id="classic-modal-slide-title"
				            disableTypography
				            className={classes.modalHeader}>
				            <IconButton
				              className={classes.modalCloseButton}
				              key="close"
				              aria-label="Close"
				              color="inherit"
				              onClick={() => this.handleKeyModalClose("modal")}>
				              <Close className={classes.modalClose} />
				            </IconButton>
				            <h4 className={classes.modalTitle}>Import Key</h4>
				          </DialogTitle>
				          <DialogContent
				            id="modal-slide-description"
				            className={classes.modalBody}>
				            {testKeyImportStatus &&
			          			<p style={testKeyImportStatus == "SUCCESS" ? 
			          				{width: "fit-content",fontSize: "16px", borderRadius: "5px", color: "white", backgroundColor: "#4caf50", padding: "5px"}
			          				: {width: "fit-content",fontSize: "16px", borderRadius: "5px", color: "white", backgroundColor: "#F44336", padding: "5px"}} >{testKeyImportStatus}</p>
			          		}
			              	<p>Please note that this operation is not reversible and might lead to data loss</p>
				          	<div>
				          		<input
				          		  accept=".csv"
				          		  className={classes.input}
				          		  style={{ display: 'none' }}
				          		  id="raised-button-file"
				          		  multiple
				          		  type="file"
				          		  onChange={this.handleFileInput}
				          		/>
				          		<label htmlFor="raised-button-file">
				          		  <Button component="span" style={{
				          		    textTransform: "none",
				          		    backgroundColor: "#607D8B",
				          		    padding: "8px 20px",
				          		    margin: "0px"
				          		  }}>
				          		    Browse
				          		  </Button>
				          		</label> 
				          		{fileToImportName &&
				          		<p style={{fontSize: "12px", color: "rgb(34, 56, 69)"}} >{fileToImportName}</p>
				          		}
				          	</div>
				          </DialogContent>
				          <DialogActions
				            className={classes.modalFooter +" " +classes.modalFooterCenter}>
				          	{!testKeyImportStatus &&  
				         	<Button
				              name={name}
				              onClick={this.handleImportKey}
				              color="success">
				              Import Key
				            </Button>
				          	}
				          </DialogActions>
				        </Dialog>
				        </React.Fragment>
			            }
			            
	        	</React.Fragment>
	        );
		}
}

export default  withStyles(testActionStyles)(TestActions);