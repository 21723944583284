import React, {Component} from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import ErrorLogo from "@material-ui/icons/Error";
import LockOutline from "@material-ui/icons/LockOutlined";

// core components
import Header from "Header/Header.jsx";
import HeaderLinks from "Header/HeaderLinks.jsx";
import Footer from "Footer/Footer.jsx";
import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Button from "CustomButtons/Button.jsx";
import Card from "Card/Card.jsx";
import CardBody from "Card/CardBody.jsx";
import CardHeader from "Card/CardHeader.jsx";
import CardFooter from "Card/CardFooter.jsx";
import CustomInput from "CustomInput/CustomInput.jsx";

import loginPageStyle from "styles/LoginPageStyle.js";

import brand_white from "assets/img/images/brand_white.png";
import CheckCircle from "@material-ui/icons/CheckCircle";

import qs from 'query-string';
import $ from 'jquery';


class LoginPage extends Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      "username" : "",
      "password" : "",
      loginErrorMessage: "",
      loginSuccessMessage: ""
    };
  }

  componentDidMount() {

    if(sessionStorage.user == "undefined"){
      sessionStorage.removeItem('user');
    }
    if(sessionStorage.user){
      this.handleRedirect(sessionStorage.user);
      return;
    }

    const token = qs.parse(this.props.location.search).token;
    const context = qs.parse(this.props.location.search).context;
    const navToTestId = qs.parse(this.props.location.search).navToTestId;
    if(token && context){
      var headersData = {
          "x-global_user-username" : context,
          "x-global_user-token" : token
        }
        $.ajax({
          type: "GET",
          url: getBaseURL() + '/react/login-session.json',
          headers: headersData
        }).done((response, status, jqXHR)=>{
          sessionStorage.setItem('user',JSON.stringify(response));
          if(navToTestId && response.role=="TEST_PARTICIPANT"){
            this.props.history.push('/home/enterprise/testHome?test_id='+navToTestId);
          }else{
            this.handleRedirect(JSON.stringify(response));
          }
          return
        }).fail((jqXHR, textStatus, errorThrown) => {
          this.setState({loginErrorMessage: "Integrated Login Failed"});
        });

      /*$.ajax({
        type: 'POST',
        url: getBaseURL() + '/init_session.json',
        headers: headersData
      }).done((response, status, jqXHR)=>{
        sessionStorage.setItem('user',response);
        this.handleRedirect(response);
      }).fail((jqXHR, textStatus, errorThrown) => {
          this.setState({loginErrorMessage: "Invalid Credentials"});
      })*/
      return
    }

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {

        const registration = qs.parse(this.props.location.search).registration;
        if (registration == "success"){
          this.setState({loginSuccessMessage: "User registration successful. Please login with your credentials."})
        }

        this.setState({ 
          cardAnimaton: "",
          "username" : this.state.username,
          "password" : this.state.password
        });
      }.bind(this),
      700
    );
  }

  handleEnterKeyPress = (e) => {
    //e.preventDefault();
    if(e.key === 'Enter'){
      this.handleLogin(e);
    }
  }

  handleInput = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var obj ={};
    obj[id] = e.target.value;
    this.setState(obj);
  }

  handleLogin = (e) => {
    e.preventDefault();

    if(localStorage.activeTestId){
      if (window.confirm('There is an active test in progress. All your current progress will be lost if you continue to login. Do you want to continue?')) {
        localStorage.removeItem("testUserData");
        localStorage.removeItem("activeTestId");
        localStorage.removeItem("activeTestQuestionNumber");
        localStorage.removeItem("activeTestAutoSaved");
        this.handleLoginRequest();
      } else {
          // Do nothing!
      }
    } else{
      this.handleLoginRequest();
    }    
  }

  handleLoginRequest(){
    if(!this.state.username){
      this.setState({loginErrorMessage: "Username not provided"});
      return
    }

    if(!this.state.password){
      this.setState({loginErrorMessage: "Password not provided"});
      return
    }

    $.ajax({
      type: 'POST',
      url: getBaseURL() + '/global_users/sign_in.js',
      data: {
        "global_user[username]" : this.state.username,
        "global_user[password]" : this.state.password
      }
    }).done((response, status, jqXHR)=>{
      sessionStorage.setItem('user',response);
      this.handleRedirect(response);
    }).fail((jqXHR, textStatus, errorThrown) => {
        var errorMessage = "Invalid Credentials"
        if(errorThrown== "Unauthorized" && jqXHR["responseText"]){
          errorMessage = jqXHR["responseText"]
        }
        this.setState({loginErrorMessage: errorMessage});
    })
  }

  handleRedirect(response){
    var role = JSON.parse(response).role;
    if(role == "TEST_ADMINISTRATOR"){
      this.props.history.push('/manage-tests');
    } else if(role == "APPLICATION_ADMINISTRATOR"){
      this.props.history.push('/manage-users');
    } else if(role == "SUPER_APPLICATION_ADMINISTRATOR"){
      sessionStorage.removeItem('user');
      window.location.href = getBaseURL() + "/manage_users"
    }else if(role == "TEST_ANALYST"){
      this.props.history.push("/analysis/test");
    } else if(role == "DATASET_REVIEW_ADMIN"){
      this.props.history.push("/dataset/preview/sample");
    } else if(role == "TEST_PARTICIPANT"){
      this.props.history.push('/home');
    } else if(role == "TEST_PARTICIPANT_UPLOAD"){
      this.props.history.push('/registration/testparticipant/upload/bulk');
    } else if(role == "METAREAD_ADMIN"){
      this.props.history.push('/metaread/beta');
    } else if(role == "METAREAD_DIAG_ADMIN"){
      this.props.history.push('/diagrams/beta');
    } else{
      if(sessionStorage.user){
        sessionStorage.removeItem('user');
      }
      this.setState({loginErrorMessage: "User Details and Roles incorrect."});
    }
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "linear-gradient(rgb(255, 255, 255), rgb(32, 54, 66))",//"url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form className={classes.form}>
                    <CardHeader className={classes.cardHeader}>
                      <img
                        name="home"
                        src={brand_white}
                        alt="..."
                        style={{height: "50px"}}
                        className={
                          classes.imgRaised +
                          " " +
                          classes.imgRounded +
                          " " +
                          classes.imgFluid
                        }
                      />
                    </CardHeader>
                    <CardBody>
                      <h4 id="testLogin" className={classes.robotoFontRegular}>Login</h4>
                      {this.state.loginErrorMessage ? 
                        <div id="errorLogin" style={{display: "flex", marginTop: "16px"}}>
                        <ErrorLogo className={classes.icon} style={{verticalAlign: "middle", marginRight: "5px", color: "#f44336"}}></ErrorLogo>
                        <p style={{display: "inline-block", color:"#f44336", fontSize: "0.8rem", overflow: "inherit", margin: "auto 0px"}} className={classes.robotoFontRegular}>{this.state.loginErrorMessage}</p> 
                        </div>
                        : null
                      }
                      {this.state.loginSuccessMessage ? 
                        <div style={{display: "flex", marginTop: "16px"}}>
                        <CheckCircle className={classes.icon} style={{verticalAlign: "middle", marginRight: "5px", color: "#4CAF50"}}></CheckCircle>
                        <p style={{display: "inline-block", color:"#4CAF50", fontSize: "0.8rem", overflow: "inherit", whiteSpace: "normal"}} className={classes.robotoFontRegular}>{this.state.loginSuccessMessage}</p> 
                        </div>
                        : null
                      }
                      <CustomInput
                        labelText="Username"
                        id="username"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                          value: this.state.username,
                          onChange: this.handleInput,
                          onKeyPress: this.handleEnterKeyPress
                        }}   
                      />
                      <CustomInput
                        labelText="Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <LockOutline 
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          ),
                          value: this.state.password,
                          onChange: this.handleInput,
                          onKeyPress: this.handleEnterKeyPress
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button className={classes.loginBtn} onClick={this.handleLogin}>
                          Login
                      </Button>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer whiteFont />
        </div>
      </div>
    );
  }
}

export default withStyles(loginPageStyle)(LoginPage);
