import React from "react";
import {getBaseURL} from "components/helpers/HostURLHelper.jsx";
import $ from 'jquery';

import withStyles from "@material-ui/core/styles/withStyles";
import navigationBarStyle from "./styles/NavigationBarStyle.js";


import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import Button from "CustomButtons/Button.jsx";

//import { Route, Switch } from "react-router";


import GridContainer from "Grid/GridContainer.jsx";
import GridItem from "Grid/GridItem.jsx";
import Home from "@material-ui/icons/Home";
import Menu from "@material-ui/icons/Menu";
import School from "@material-ui/icons/School";

import brand_white from "assets/img/images/brand_white.png";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import AccountCircle from "@material-ui/icons/AccountCircle";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

class NavigationBar extends React.Component {

	constructor(props) {
	    super(props);
	    this.state = {
	      drawerOpen: true,
	      activePage: "",
	      username: "",
	      isUserAnalyst: false,
	      isUserMetaReadAdmin: false
	    };
	}

	componentDidMount(){
		const currentUrl = this.props.location.pathname;
		if(currentUrl == "/manage-tests"){
			this.setState({activePage: "manageTest"});
		}else if(currentUrl == "/test_infos/new"){
			this.setState({activePage: "createTest"});
		}else if(currentUrl == "/offline_test"){
			this.setState({activePage: "createTest"});
		}else if(currentUrl == "/schedule-tests"){
			this.setState({activePage: "manageSchedule"});
		}else if(currentUrl == "/scheduled_tests/new"){
			this.setState({activePage: "scheduleTest"});
		}else if(currentUrl == "/test_template_infos"){
			this.setState({activePage: "manageTestModel"});
		}else if(currentUrl == "/total-test-analysis" || currentUrl == "/test-paper-analysis"){
			this.setState({activePage: "manageSchedule"});
		}else if(currentUrl == "/overall_analysis"){
			this.setState({activePage: "overallAnalysis"});
		}else if(currentUrl == "/metaread/beta"){
			this.setState({activePage: "metareadConvertPage"});
		}else if(currentUrl == "/metaread/store"){
			this.setState({activePage: "metareadStorePage"});
		}


		
		if(!sessionStorage.as_username){
			$.ajax({
	          type: "GET",
	          url: getBaseURL() + '/react/test-login.json',
	        }).done((data) => {
	   			this.setState({username: data.username});
	   			sessionStorage.setItem('as_username', data.username);
	   			for(var i in data["roles"]){
	   				if(data["roles"][i].name == "TEST_ANALYST"){
	   					sessionStorage.setItem('as_isUserAnalyst', "Y");
	   					this.setState({isUserAnalyst: true});
	   				}
	   				if(data["roles"][i].name == "METAREAD_ADMIN"){
	   					sessionStorage.setItem('as_isUserMetaReadAdmin', "Y");
	   					this.setState({isUserMetaReadAdmin: true});
	   				}	
	   			}
	        });
        } else{
        	this.setState({username: sessionStorage.as_username});
        }
        
        if(sessionStorage.as_isUserAnalyst){
        	this.setState({isUserAnalyst: true});
        }else{
        	this.setState({isUserAnalyst: false});
        }

        if(sessionStorage.as_isUserMetaReadAdmin){
        	this.setState({isUserMetaReadAdmin: true});
        }else{
        	this.setState({isUserMetaReadAdmin: false});
        }

	}

  	goToManageTests = (e) =>{
  		this.props.history.push('/manage-tests');
  	}

  	goToCreateTest = (e) =>{
  		this.setState({activePage: "createTest"})
  		this.props.history.push('/test_infos/new');
  	}

  	goToManageSchedules = (e) => {
  		this.props.history.push('/schedule-tests');
  	}

  	goToScheduleTest = (e) => {
  		this.props.history.push('/scheduled_tests/new');
  	}

  	goToManageTestModel = (e) => {
  		this.props.history.push('/test_template_infos');
  	}

  	goToOverallAnalysis = (e) => {
  		this.props.history.push('/overall_analysis');
  	}

  	goToMetaReadConvertPage = (e) => {
  		this.props.history.push('/metaread/beta');
  	}

  	goToMetaReadStorePage = (e) => {
  		this.props.history.push('/metaread/store');
  	}

  	goToAnalyticsDashboard = (e) => {
  		window.open('/analysis/test','_blank');
  	}

	render() {
		 const { classes} = this.props;
		return (
			  	<Drawer open={this.state.drawerOpen}
		 	         variant="permanent"
		 	        anchor="left"
		 	        classes={{
		 	          paper: classes.drawerPaper
		 	        }}
		 	      	>
		 	        <div className={classes.toolbar} style={{paddingTop: "20px", paddingBottom: "20px",  textAlign: "center"}}> 
		 	        <GridContainer style={{margin: "auto"}} justify="center" alignItems="center" direction="row">
          				<GridItem xs={12} sm={12} md={12}> 
		 	        		<AccountCircle style={{fontSize: "96px", width: "100%"}} />
		 	        		<p style={{fontSize: "0.8rem", textTransform: "capitalize"}} className={classes.robotoFontRegular}>{this.state.username}</p>
		 	        	</GridItem> 
		 	         </GridContainer>	
		 	        </div>
		 	        {//!this.state.isUserMetaReadAdmin &&
		 	        	<React.Fragment>  
			 	        <List className={classes.list}>
			 	        	<Button
						          color="transparent"
						          onClick={this.goToManageTests}
						          className={this.state.activePage == "manageTest"? classes.navLinkActive : classes.navLink}						          
						        >
						        <Menu/> Manage Tests
						    </Button>
					    </List>		 	
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToCreateTest}
					          data-cy="create test link"
					          className={  	
					          	this.state.activePage == "createTest"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Create Test
					        </Button>
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManageSchedules}
					          data-cy="manage schedules link"
					          className={  	
					          	this.state.activePage == "manageSchedule"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Manage Schedules
					        </Button> 
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToScheduleTest}
					          data-cy="schedule test link"
					          className={  	
					          	this.state.activePage == "scheduleTest"? classes.navLinkActive : classes.navLink
					          }	
					        >
					         <Menu /> Schedule Test
					        </Button>				        
						</List>
						<List className={classes.list}>
					        <Button
					          color="transparent"
					          onClick={this.goToManageTestModel}
					          className={  	
					          	this.state.activePage == "manageTestModel"? classes.navLinkActive : classes.navLink
					          }
					          data-cy="create test model link"
					        >
					         <Menu /> Create Test Model
					        </Button>				        
						</List>
						{ this.state.isUserAnalyst &&
							<React.Fragment>
								<List className={classes.list}>
							        <Button
							          color="transparent"
							          onClick={this.goToOverallAnalysis}
							          className={  	
							          	this.state.activePage == "overallAnalysis"? classes.navLinkActive : classes.navLink
							          }	
							        >
							         <Menu /> Overall Analysis 
							        </Button>				        
								</List> 
						</React.Fragment>
						}
						</React.Fragment> 
					}
					{this.state.isUserMetaReadAdmin &&
						<React.Fragment>
							<List className={classes.list}>
						        <Button
						          color="transparent"
						          onClick={this.goToMetaReadConvertPage}
						          className={  	
						          	this.state.activePage == "metareadConvertPage"? classes.navLinkActive : classes.navLink
						          }	
						        >
						         <Menu /> Content Extraction
						        </Button>				        
							</List>
							<List className={classes.list}>
						        <Button
						          color="transparent"
						          onClick={this.goToMetaReadStorePage}
						          className={  	
						          	this.state.activePage == "metareadStorePage"? classes.navLinkActive : classes.navLink
						          }	
						        >
						         <Menu /> Content Store
						        </Button>				        
							</List>
							{/*<List className={classes.list}>
						        <Button
						          color="transparent"
						          onClick={this.goToMetaReadConvertPage}
						          className={  	
						          	this.state.activePage == "metareadModulePage"? classes.navLinkActive : classes.navLink
						          }
						          disabled={true}	
						        >
						         <Menu /> Content Modules 
						        </Button>				        
							</List>*/}
						</React.Fragment>

					}
					{ this.state.isUserAnalyst &&
							<React.Fragment>
								<List className={classes.list}>
									<Button
							          color="transparent"
							          onClick={this.goToAnalyticsDashboard}
							          className={  	classes.navLink}
							          style={{backgroundColor: "white",color: "#3f51b5", fontWeight: "400"}}	
							        >
							         <OpenInNewIcon /> Analysis Dashboard 
							        </Button>
								</List> 	
						</React.Fragment>
						}  
 	      		</Drawer>
 	      		);
}
}
export default withWidth()(withStyles(navigationBarStyle)(NavigationBar));
