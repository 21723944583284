import React from "react";
import ReactDOM from "react-dom";
import QuestionHeader from "components/test/view/QuestionHeader.jsx";
import QuestionText from "components/test/view/QuestionText.jsx";
import QuestionImages from "components/test/view/QuestionImages.jsx";
import SingleAnswerOptions from "components/test/view/SingleAnswerOptions.jsx";
import MultipleAnswerOptions from "components/test/view/MultipleAnswerOptions.jsx";
import NumericalAnswerComponent from "components/test/view/NumericalAnswerComponent.jsx";
import QuestionFooter from "components/test/view/QuestionFooter.jsx";

import QuestionTextInput from "components/test/view/QuestionTextInput.jsx";
import SingleAnswerQuestionChoices  from "components/lov/SingleAnswerQuestionChoices"
import MultipleAnswerQuestionChoices  from "components/lov/MultipleAnswerQuestionChoices"

import MatrixGridAnswerComponent from "components/test/view/MatrixGridAnswerComponent.jsx";
import MatrixGridKeyComponent from "components/test/view/MatrixGridKeyComponent.jsx";
import MatrixGridListsComponent from "components/test/view/MatrixGridListsComponent.jsx";

 export default  class TestQuestionsContainer extends React.Component {

 	componentDidMount(){
 		if(window.MathJax)
 		window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
 	}

 	componentDidUpdate(){
 		if(window.MathJax)
 		window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub, ReactDOM.findDOMNode(this)]);
 	}

 	convNumbersToLetters(int) {
    return String.fromCharCode(64 + parseInt(int));
   	} ; 

   	convMAQDataNumbersToLetters(ansNumberArray){
	    if(!ansNumberArray || !ansNumberArray[0]){
	        return;
	    }
    	var ansLetterArray = [];
    	for(var i in ansNumberArray[0]){
    		ansLetterArray.push(String.fromCharCode(64 + parseInt(ansNumberArray[0][i])));
    	}
	    return ansLetterArray;
	}

	getInlineComponentStyles(data, index){
		if(!data || data.length < 1){
			return false
		}
		if (data[index][1].includes("inline")){
			return true
		}
		if(index > 0 && data[index-1][1].includes("inline")){
			return true
		}
		return false
	}

	render() {
		const { testData , nextQ, prevQ ,lastQ, firstQ, handleAnswerChange, handleMultipleAnswerChange,
			handleMarkForReviewChange, handleClearResponse, isEditMode,handleEditTestData,
			handleSaveQuestionData,handleDiscardQuestionData,handleClearQuestionData, questionSavedIndicator, 
			handleAddTextComponent,handleAddListComponent,handleRemoveListComponent, handleRemoveTextComponent,handlePasteEvent,
			handleRemoveImageComponent,handleTestModeChange,isTestScheduled,isFetchQuestionsView,handleImageFileUpload, noQuestionFooter,
			handleClearKey} = this.props;
		return(
			<div style={{paddingBottom: "50px"}}> {/*FOR THE PAGE TO BE SCROLLABLE WITHOUT FOOTER OCCLUSION*/}
			<QuestionHeader number={testData["question_no"]} 
			pattern={testData["pattern_name"]} 
			subject={testData["subject_name"]}
			positiveMarks={testData["positive_marks"]}
			negativeMarks={testData["negative_marks"]}
			partialMarks={testData["partial_marks"]}
			isEditMode={isEditMode}
			handleSaveQuestionData={handleSaveQuestionData}
			handleDiscardQuestionData={handleDiscardQuestionData}
			handleClearQuestionData={handleClearQuestionData}
			questionSavedIndicator={questionSavedIndicator}
			handleTestModeChange={handleTestModeChange}
			{...this.props}
			/>
			<hr/>
			{ testData["question_content"] && testData["pattern_short_name"] == 'PASSAGE' && !isEditMode &&
				<h4 style={{marginLeft: "15px", paddingTop:"4px",
						  fontWeight: "600", fontSize: "16px"}}>Passage</h4>
			}
			{ testData["question_content"] && testData["pattern_short_name"] == 'PASSAGE' &&
			<div style={{paddingLeft: "15px", paddingRight: "15px"}}>
				{testData["question_content"].passage.map((item, index) => (
					<React.Fragment key={index}
					/*style={
						  this.getInlineComponentStyles((testData["question_content"]["question"]),index)
				          ? {display: "inline-block"} : {} }*/ > 
					{ isEditMode && !isTestScheduled ? 
						<React.Fragment>
						  <h4 style={{marginLeft: "20px", paddingTop:"10px",
						  fontWeight: "600", fontSize: "18px"}}>Passage</h4>
						  <QuestionTextInput text={item[0]} name={"passage_"+index+"_0"}
						  handleEditTestData={handleEditTestData}
						  handleAddTextComponent={handleAddTextComponent}
						  handleRemoveTextComponent={handleRemoveTextComponent}
						  handlePasteEvent={handlePasteEvent}
						  handleImageFileUpload={handleImageFileUpload}
						  rows="3"/>
						 </React.Fragment>
						:
						item[0] ? 
						 <QuestionText text={item[0]}/>
						: null
					}
					{ item[1] ?
						<QuestionImages 
						src={item[1]} 
						name={"passage_"+index+"_1"}
						isEditMode={isEditMode && !isTestScheduled}
						handleRemoveImageComponent={handleRemoveImageComponent} 
						/>
					: null
					}
					</React.Fragment>
				))}
				</div>				
			}
			{ testData["question_content"] && testData["pattern_short_name"] == 'PASSAGE' && !isEditMode &&
				<h4 style={{marginLeft: "15px", paddingTop:"4px",
						  fontWeight: "600", fontSize: "16px"}}>Question</h4>
			}
			{ testData["question_content"] &&
				<div style={{paddingLeft: "15px", paddingRight: "15px"}}>
				{testData["question_content"].question.map((item, index) => (
					<React.Fragment key={index}
					/*style={
						  this.getInlineComponentStyles((testData["question_content"]["question"]),index)
				          ? {display: "inline-block"} : {} }*/ > 
					{ isEditMode && !isTestScheduled ? 
						<React.Fragment>
						  <h4 style={{marginLeft: "20px", paddingTop:"10px",
						  fontWeight: "600", fontSize: "18px"}}>Question</h4>
						  <QuestionTextInput text={item[0]} name={"question_"+index+"_0"}
						  handleEditTestData={handleEditTestData}
						  handleAddTextComponent={handleAddTextComponent}
						  handleRemoveTextComponent={handleRemoveTextComponent}
						  handlePasteEvent={handlePasteEvent}
						  handleImageFileUpload={handleImageFileUpload}
						  rows="3"/>
						 </React.Fragment> 
						:
						item[0] ? 
						 <QuestionText text={item[0]}/>
						: null
					}
					{ item[1] ?
						<QuestionImages 
						src={item[1]} 
						name={"question_"+index+"_1"}
						isEditMode={isEditMode && !isTestScheduled}
						handleRemoveImageComponent={handleRemoveImageComponent} 
						/>
					: null
					}
					</React.Fragment>
				))
				}
				</div>
			}
			<div style={{paddingBottom: "20px"}}></div>
			{testData["question_content"] && (testData["pattern_short_name"] == 'MM' || ( testData["pattern_short_name"] == 'MCQ' && testData["question_content"].list1 && testData["question_content"].list2 && testData["question_content"].list1.length > 0 && testData["question_content"].list2.length>0))?
				<MatrixGridListsComponent {...this.props}
				list1={testData["question_content"].list1}
				list2={testData["question_content"].list2}
				handleAddListComponent={handleAddListComponent} 
				handleRemoveListComponent={handleRemoveListComponent}
				isEditMode={isEditMode}/>
	 		     : null
			}
			{ testData["question_content"] && (testData["pattern_short_name"] == 'MCQ' || testData["pattern_short_name"] == 'PASSAGE') ?
				<SingleAnswerOptions {...this.props}
				answer={""} 
				AnswerKey={testData.key &&  JSON.parse(testData.key) && JSON.parse(testData.key).length > 0 ? this.convNumbersToLetters(JSON.parse(testData.key)[0][0]) : ""} 
				options={testData["question_content"].options} 
				answerChanged={handleAnswerChange}
				isEditMode={isEditMode && !isTestScheduled}
				handleEditTestData={handleEditTestData}
			  	handlePasteEvent={handlePasteEvent}
			  	handleRemoveImageComponent={handleRemoveImageComponent}
			  	handleImageFileUpload={handleImageFileUpload} />
	 		     : null
			}
			{ testData["question_content"] && testData["pattern_short_name"] == 'MAQ' ?
				<MultipleAnswerOptions {...this.props}
				answer={""} 
				AnswerKey={this.convMAQDataNumbersToLetters(JSON.parse(testData.key))} 
				options={testData["question_content"].options} 
				answerChanged={handleMultipleAnswerChange}
				isEditMode={isEditMode && !isTestScheduled}
				handleEditTestData={handleEditTestData}
			  	handlePasteEvent={handlePasteEvent}
			  	handleRemoveImageComponent={handleRemoveImageComponent}
			  	handleImageFileUpload={handleImageFileUpload}/>
	 		     : null
			}
			{ !isEditMode && testData["question_content"] && (testData["pattern_short_name"] == 'NUMERICAL' || testData["pattern_short_name"] == 'INTEGER'  )?
				<NumericalAnswerComponent {...this.props}
				answer={""} 
				AnswerKey={testData.key &&  testData.key.length > 0 ? JSON.parse(testData.key)[0][0] : ""}
				patternType={testData["pattern_short_name"]} 
				options={testData["question_content"].options}
				answerChanged={handleEditTestData}/>
	 		     : null
			}
			{ !isEditMode && testData["question_content"] && (testData["pattern_short_name"] == 'MM')?
				<MatrixGridAnswerComponent {...this.props}
				answer={""} 
				showSolutions={true}
				AnswerKey={JSON.parse(testData.key)} 
				list1={testData["question_content"].list1}
				list2={testData["question_content"].list2} 
				answerChanged={handleEditTestData}/>
	 		     : null
			}
			{ testData["key"] && JSON.parse(testData["key"]) && JSON.parse(testData["key"]).length > 0 && isEditMode && (testData["pattern_short_name"] == 'MCQ' || testData["pattern_short_name"] == 'PASSAGE') ?
				<div style={{margin: "20px"}}>
				<h4 style={{marginLeft: "20px", paddingTop:"10px",
				fontWeight: "600", fontSize: "18px"}}>Key</h4>
				{JSON.parse(testData["key"]).map((item, index) => (
					<div key={index} style={{marginLeft: "20px", paddingTop: "10px"}}>
					  <SingleAnswerQuestionChoices 
				      	name={"key_"+index+"_0"}
				      	formControlWidth="20%"
		                value={item[0] ? item[0] : ""}
		                change={handleEditTestData}
		                handleClearKey={handleClearKey}
		              />
					</div>
				))}
				</div>
	 		     : null  
			}
			{ testData["key"] && isEditMode && (testData["pattern_short_name"] == 'MAQ') ?
				<div style={{margin: "20px"}}>
				<h4 style={{marginLeft: "20px", paddingTop:"10px",
				fontWeight: "600", fontSize: "18px"}}>Key</h4>
				{JSON.parse(testData["key"]).map((item, index) => (
					<div key={index} style={{marginLeft: "20px", paddingTop: "10px"}}>
					  <MultipleAnswerQuestionChoices 
				      	name={"key_"+index+"_0"}
				      	formControlWidth="20%"
		                value={item ? item : []}
		                change={handleEditTestData}
		                handleClearKey={handleClearKey}
		              />
					</div>
				))}
				</div>
	 		     : null  
			}
			{ testData["key"] && isEditMode && (testData["pattern_short_name"] == 'NUMERICAL' || testData["pattern_short_name"] == 'INTEGER')?
				<div style={{margin: "20px"}}>
				<h4 style={{marginLeft: "20px", paddingTop:"10px",
				fontWeight: "600", fontSize: "18px"}}>Key</h4>
				{JSON.parse(testData["key"]).map((item, index) => (
					<div key={index} style={{width: "25%"}}>
						  <QuestionTextInput text={item[0] ? item[0] : ""} name={"key_"+index+"_0"}
						  handleEditTestData={handleEditTestData}
						  rows="1"
						  isSingleComponent={true}
						  noImageAttachment={true}/>
					</div>
				))}
				</div>
	 		     : null  
			}
			{ testData["key"] && isEditMode && testData["question_content"] && testData["pattern_short_name"] == 'MM' ?
				<div style={{margin: "20px"}}>
				<h4 style={{marginLeft: "20px", paddingTop:"10px",
				fontWeight: "600", fontSize: "18px"}}>Key</h4>
				{testData["question_content"].list1 && testData["question_content"].list1.map((item, index) => (
					<div key={index} style={{marginLeft: "20px", paddingTop: "10px"}}>
					  <p style={{fontWeight: "400"}}>{"Part " + String.fromCharCode(65+index)}</p>
					  <MatrixGridKeyComponent 
				      	name={"key_"+index+"_0"}
				      	formControlWidth="20%"
		                value={JSON.parse(testData["key"])[index] ? JSON.parse(testData["key"])[index] : []}
		                change={handleEditTestData}
		                list2={testData["question_content"].list2}
		              />
					</div>
				))}
				</div>
	 		     : null  
			}

			{ testData["solution"]  ?
				<div style={{margin: "20px"}}>
				<h4 style={{marginLeft: "20px", paddingTop:"10px",
				fontWeight: "600", fontSize: "18px", marginBottom: "20px"}}>Solution</h4>
				{JSON.parse(testData["solution"]).map((item, index) => (
					<div key={index}
					/*style={
						  this.getInlineComponentStyles(JSON.parse(testData["solution"]),index)
				          ? {display: "inline-block"} : {} }*/ >
					{ isEditMode ? 
						  <QuestionTextInput text={item[0]} name={"solution_"+index+"_0"}
						  handleEditTestData={handleEditTestData}
						  handleAddTextComponent={handleAddTextComponent}
						  handleRemoveTextComponent={handleRemoveTextComponent}
						  handlePasteEvent={handlePasteEvent}
						  handleImageFileUpload={handleImageFileUpload}
						  rows="3"/>
						:
						item[0] ? 
						 <QuestionText text={item[0]}/>
						: null
					}
					{ item[1] ?
						<QuestionImages 
						src={item[1]} 
						name={"solution_"+index+"_1"}
						isEditMode={isEditMode}
						handleRemoveImageComponent={handleRemoveImageComponent} 
						backgroundColor="transparent"
						/>
					: null
					}
					</div>
				))}
				</div>
	 		     : null  
			}
			{ testData["question_content"] && !noQuestionFooter ?
			<QuestionFooter {...this.props} nextQ={nextQ} prevQ={prevQ} lastQ={lastQ} firstQ={firstQ} 
			markedForReview={false} 
			markForReviewChanged={handleMarkForReviewChange}
			handleClearResponse={handleClearResponse}
			isFetchQuestionsView={isFetchQuestionsView}/>
			: null
			}
			</div>
			);
	}

}