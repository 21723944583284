import React, {useState, useEffect} from "react";
import {getBaseURL,getCSRFToken} from "../helpers/HostURLHelper.jsx";
import $ from 'jquery';

import GridContainer from '../../exports/components/Grid/GridContainer';
import GridItem from '../../exports/components/Grid/GridItem';
import Button from "../../exports/components/CustomButtons/Button.jsx";

import makeStyles from "@material-ui/core/styles/makeStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ComboBox from "../analyst/core/ComboBox.jsx"

const useStyles= makeStyles((theme) => ({
	root: {
    '& > *': {
      margin: "10px",
    },
  },

  input:{
  	width: '83.5%',
  	[theme.breakpoints.down("md")]:{
  		width: "96%"
  	}   
  },
  select:{
  	width:'83.5%',
  	[theme.breakpoints.down("md")]:{
  		width: "96%"
  	} 
  },
  addUserBtn:{
  	width: '84%',
  	textTransform: "none",
  	backgroundColor: "#3f51b5",
  	padding: "8px",
  	"&:hover":{
  		backgroundColor: "#3f51b5"
  	},
  	[theme.breakpoints.down("md")]:{
  		width: "96%"
  	}
  }
}));

function Alert(props) {
  return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

const RegisterNewUser = (props) => {
	const {programs,branches, sections, userRoles, userGroups, history}=props;

	const [userInfo,setUserInfo]=useState({userId:"",name:"",email:"",phone:null,branchId:null,
	programId:null,sectionId:null, password:"", confPassword:"",roleId:null, groupId:null});

	const [errId, setErrId]=useState("");
	const [errName, setErrName]=useState("");
	const [errEmail, setErrEmail]=useState("");
	const [errPhone, setErrPhone]=useState("");
	const [errBranchId, setErrBranchId]=useState("");
	const [errProgId, setErrProgId]=useState("");
	const [errSectionId, setErrSectionId]=useState("");
	const [errPswd, setErrPswd]=useState("");
	const [errConPswd, setErrConPswd]=useState("");
	const [errRoleId, setErrRoleId]=useState("");
	const [errGroupId, setErrGroupId]=useState("");
	const [showPassword, setShowPassword]=useState(false);

	const classes=useStyles();

	const [error,setError]=useState('');
	const [alertOpen,setAlertOpen]=useState(false);

	const handleShowPassword = (e) => {
		setShowPassword(!showPassword);
	}

	function validateEmail(email) 
    {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    function validatePostData(data){

    	const {userId, name,email,phone,branchId,programId,sectionId,password,confPassword,roleId,groupId}=data;

		var isError = false;

			if(userId == ""){
				setErrId("This Field is required");
				isError = true;
			}

			if(name == ""){
				setErrName("This Field is required");
				isError = true;
			}

			if(email == ""){
				setErrEmail("This Field is required");
			}

			if(email && !validateEmail(email)){
				setErrEmail("Please Enter a valid email");
				isError = true;
			}

			if(phone == null){
				setErrPhone("This Field is required");
				isError = true;
			}

			if(phone && (isNaN(phone) || phone <999999999)){
				setErrPhone("Invalid Phone Number");
				isError = true;
			}
			if(branchId == null){
				setErrBranchId("This Field is required");
				isError = true;
			}
			if(programId == null){
				setErrProgId("This Field is required");
				isError = true;
			}
			if(sectionId == null){
				setErrSectionId("This Field is required");
				isError = true;
			}
			if(password== ""){
				setErrPswd("This Field is required");
				isError = true;
			}
			if(password && password.length <8){
				setErrPswd("Invalid Password");
				isError = true;
			}
			if(confPassword== ""){
				setErrConPswd("This Field is required");
				isError = true;
			}

			if(password && confPassword && password !== confPassword){
				setErrConPswd("Password does not match");
				isError = true;
			}

			// if(roleId == null){
			// 	setErrRoleId("This Field is required");
			// 	isError = true;
			// }
			if(groupId == null){
				setErrGroupId("This Field is required");
				isError = true;
			}

		return !isError;
    }

	const handleClick = (e) => {
		e.preventDefault();

		const {userId, name,email,phone,branchId,programId,sectionId,password,confPassword,roleId,groupId}=userInfo;
		
		if(!validatePostData(userInfo)){
			return;
		}
			
		const postData={global_user:{username:userId,name,email,phone,global_branch_id:branchId,global_section_id:sectionId,
			global_program_id:programId, password,password_confirmation:confPassword,user_role:roleId,user_group:groupId}};



			$.ajax({
		      type: 'POST',
		      url: getBaseURL()+'/create_user.json',
		      data: postData,
		      	headers: {
			      	'X-CSRF-Token': getCSRFToken()
			   	}
		    }).done((response) => {	

		    	setUserInfo({userId:"", name:"",email:"",phone:"",
		    		branchId:null,programId:null,sectionId:null,password:"",confPassword:"",roleId:null,groupId:null});
		    		history.push('/manage-users');
		    }).fail((error)=>{
		    	if(validatePostData(userInfo))
		    	{
		    		setError("Something Went Wrong !!");
			    	setAlertOpen(true);
		    	}   	
		    })
	}

	const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };


	return(
		<React.Fragment>
		{
			error &&
			<Snackbar open={alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} 
			onClose={handleCloseAlert} style={{marginTop: "30px"}}>
				 <Alert onClose={handleCloseAlert} severity="error">
						{error}
				</Alert>
			</Snackbar>
		}
		<GridItem  style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={4} style={{padding: '12px'}}>
		</GridItem>
		<GridItem  style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={4} style={{padding: '12px'}}>
			
			<form className={classes.root}>
				<TextField variant="outlined" label="UserID" required className={classes.input} 
				name="userId"
				value={userInfo.userId}
				onChange={(e)=> {setErrId(false);
					setUserInfo({...userInfo, userId: e.target.value})}}
				error={errId ? true : false}
				helperText={errId ? errId: ""}
				/>

				<TextField variant="outlined" label="Full Name" required className={classes.input} 
				name="name"
				value={userInfo.name}
				onChange={(e)=> {setErrName(false);
					setUserInfo({...userInfo, name: e.target.value})}}
				error={errName ? true : false}
				helperText={errName ? errName: ""}
				/>

				<TextField variant="outlined" label="Email" required className={classes.input} 
				name="email"
				value={userInfo.email}
				onChange={(e)=> {setErrEmail(false);
					setUserInfo({...userInfo, email: e.target.value})}}
				error={errEmail ? true : false}
				helperText={errEmail ? errEmail: ""}
        />

				<TextField variant="outlined" label="Phone" required className={classes.input} 
				name="phone"
				value={userInfo.phone}
				onChange={(e)=> {setErrPhone(false);
					setUserInfo({...userInfo, phone: e.target.value})}}
				error={errPhone ? true : false}
				helperText={errPhone ? errPhone: ""}
        />

				{/*<TextField className={classes.input} variant="outlined" label="Program" select required helperText="Select Program"
				name="programId"
				value={userInfo.programId}
				onChange={(e)=> {setErrProgId(false);
					setUserInfo({...userInfo, programId: e.target.value})}}
				error={errProgId ? true : false}
				helperText={errProgId ? errProgId: ""}
				>
				  {
				  	programs.map((program) => {
				  		return(<MenuItem key={program.id} value={program.id}>{program.name}</MenuItem>);
				  	})
				  }
			  	</TextField>*/}
			  	<div className={classes.input}>
			  		<ComboBox  required 
			  			error={errProgId ? true : false} 
			  			helperText={errProgId ? errProgId: ""} 
			  			options={programs} 
			  			width="100%"
			  			onChange={(e,option)=> {setErrProgId(false);
						setUserInfo({...userInfo, programId: option.id})}} 
						label="Program"/>
				</div>
				{/*<TextField className={classes.input} variant="outlined" label="Branch" select required 
				name="branchId"
				value={userInfo.branchId}
				onChange={(e)=> {setErrBranchId(false);
					setUserInfo({...userInfo, branchId: e.target.value})}}
				error={errBranchId ? true : false}
				helperText={errBranchId ? errBranchId: ""}
				>
				  {
				  	branches.map((branch) => {
				  		return(<MenuItem value={branch.id}>{branch.name}</MenuItem>);
				  	})
				  }
			  </TextField>*/}
			  	<div className={classes.input} style={{margin: "20px 10px"}}>
			  		<ComboBox  required 
			  		error={errBranchId ? true : false} 
			  		helperText={errBranchId ? errBranchId: ""} 
			  		options={branches}
			  		width="100%" 
			  		onChange={(e,option)=> {setErrBranchId(false);
						setUserInfo({...userInfo, branchId: option.id})}} 
						label="Branch"/>
				</div>


				{/*<TextField className={classes.select} variant="outlined" label="Section" select required
				name="sectionId"
				value={userInfo.sectionId}
				onChange={(e)=> {setErrSectionId(false);
					setUserInfo({...userInfo, sectionId: e.target.value})}}
				error={errSectionId ? true : false}
				helperText={errSectionId ? errSectionId: ""}
				>
				  {
				  	sections.map((section) => {
				  		return(<MenuItem value={section.id}>{section.name}</MenuItem>);
				  	})
				  }
				</TextField>*/}
				<div className={classes.input}>
			  		<ComboBox  required 
			  		error={errSectionId ? true : false}
			  		helperText={errSectionId ? errSectionId: ""}
			  		options={sections}
			  		width="100%" 
			  		onChange={(e,option)=> {setErrSectionId(false);
						setUserInfo({...userInfo, sectionId: option.id})}} 
						label="Section"/>
				</div>
				<TextField className={classes.input} variant="outlined" label="Password" required
				name="password"
				value={userInfo.password}
				onChange={(e)=> {setErrPswd(false);
					setUserInfo({...userInfo, password: e.target.value})}}
				error={errPswd ? true : false}
				helperText={errPswd ? errPswd: ""}
				type={showPassword ? 'text' : 'password'}
					InputProps={{
					endAdornment:(
	              <InputAdornment position="end">
	                <IconButton onClick={handleShowPassword}> {showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
	              </InputAdornment>)
	          }}/>

				<TextField className={classes.input} variant="outlined" label="Confirm Password" required
				name="confPassword"
				value={userInfo.confPassword}
				onChange={(e)=> {setErrConPswd(false);
					setUserInfo({...userInfo, confPassword: e.target.value})}}
				error={errConPswd ? true : false}
				helperText={errConPswd ? errConPswd: ""}
				type={showPassword ? 'text' : 'password'}
					InputProps={{
					endAdornment:(
	              <InputAdornment position="end">
	                <IconButton onClick={handleShowPassword}> {showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
	              </InputAdornment>)
	          }}/>

			  {/*<TextField className={classes.select} variant="outlined" label="User  Role" select required
			  	name="roleId"
				value={userInfo.roleId}
				onChange={(e)=> {setErrRoleId(false);
					setUserInfo({...userInfo, roleId: e.target.value})}}
				error={errRoleId ? true : false}
				helperText={errRoleId ? errRoleId: ""}>
				  {
				  	userRoles.map((role) => {
				  		return(<MenuItem value={role.id}>{role.name}</MenuItem>);
				  	})
				  }
				</TextField>*/}
				{/*<TextField className={classes.select} variant="outlined" label="User Group" select required
				name="groupId"
				value={userInfo.groupId}
				onChange={(e)=> {setErrGroupId(false);
					setUserInfo({...userInfo, groupId: e.target.value})}}
				error={errGroupId ? true : false}
				helperText={errGroupId ? errGroupId: ""}>
				 {
				  	userGroups.map((group) => {
				  		return(<MenuItem value={group.id}>{group.name}</MenuItem>);
				  	})
				  }
				</TextField>*/}
				<div className={classes.input}>
			  		<ComboBox  required 
			  		error={errGroupId ? true : false}
			  		helperText={errGroupId ? errGroupId: ""}
			  		options={userGroups} 
			  		width="100%"
			  		onChange={(e,option)=> {setErrGroupId(false);
						setUserInfo({...userInfo, groupId: option.id})}} 
						label="User Group"/>
				</div>
				<Button className={classes.addUserBtn} onClick={handleClick}>
			    	<Typography component="h3">Add New user</Typography>
			  	</Button>
			</form>
			
		</GridItem>
		<GridItem  style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={4} style={{padding: '12px'}}>
		</GridItem>

		</React.Fragment>
		
	);
}

export default RegisterNewUser;
