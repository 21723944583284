import React, {Component} from "react";
import qs from 'query-string';
import {getBaseURL} from "../helpers/HostURLHelper.jsx";
import $ from 'jquery';
import GridContainer from '../../exports/components/Grid/GridContainer';
import GridItem from '../../exports/components/Grid/GridItem';
import Button from "../../exports/components/CustomButtons/Button.jsx";

import testFormStyle from "../styles/TestFormStyle.js";
import modalStyle from "../../exports/assets/jss/material-kit-react/modalStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";

import MUIDataTable from "mui-datatables";

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Slide from "@material-ui/core/Slide";
import Close from "@material-ui/icons/Close";

import Hidden from "@material-ui/core/Hidden";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const getMuiThemeForTable = createMuiTheme({
    overrides: {
      MuiPaper: {
      	elevation4:{
      		boxShadow: "none"
      	},
      },
      MUIDataTable:{
      	responsiveStacked: {
      		overflow: "visible",
      		overflowX: "visible"
      	},
      	responsiveBase: {
      		overflowY: "hidden",
      	}
	  },
     MuiTableCell:{
      	root:{
      		padding: "4px 16px",
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.85rem',
      		height: "48px",
      		width: '200px',

      	}
      },
      MUIDataTableHeadCell: {
      	root:{
      		color: 'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem',
      		fontWeight: '500',
      		height: '56px',
      		textAlign: 'center'      		
      	},
      	fixedHeader:{
      		zIndex: "none",
      	}
      },
      MUIDataTableBodyCell:{
      	root:{
      		//height: '48px'
      	}
      },
      MuiTablePagination:{
      	root:{
          borderBottom: '0px'
        },
      	caption:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	},
      	select:{
      		color:'rgba(0,0,0,0.54)',
      		fontSize: '0.75rem'
      	}
      },
      typography: {
    	useNextVariants: true,
  	},
    },
    typography: {
    	useNextVariants: true,
  	},
  });

const getMuiThemeForModal =  createMuiTheme({
    overrides: {
    	MuiDialog:{
    		paper:{
    			width: '400px',
    			height: '320px'
    		}
    	},
      MuiModal:{
      	root:{
      		zIndex: "90000"
      	}
    },
	    typography: {
	    	useNextVariants: true,
	  	},
    },
    typography: {
    	useNextVariants: true,
  	}
  });

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 5
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  IconButton:{
  	padding: "7px",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "0px 3px 3px 0px",
    border: "1px solid #3f51b5",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
      border: "1px solid #57849b",
    },
  },
  searchButton:{
  	textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "white",
    borderRadius: "3px",
    textAlign: "right",
    "&:hover,&:focus": {
      color: "white",
      background: "#57849b",
    },
  },
  ...testFormStyle,
  cancelButton:{
  	textTransform: "none",
    backgroundColor: "#00000014",
    color: "rgba(0, 0, 0, 0.54)",
    borderRadius: "3px",
    textAlign: "right"
  },
  ...modalStyle,
  form: {
    '& > *': {
      margin: "8px",
    }
  },
  inputLabel:{
  	width: '96%' 
  },
  multiModal:{
  	width: '550px',
    height: '420px'
  }
};


function Alert(props) {
  return (<MuiAlert elevation={6} variant="filled" {...props} />);
}

class UserMapping extends Component{
	constructor(props){
		super(props);
		this.state={
			userMappingTableData:[],
			userMappingTableDataMob:[],
			userGroups:[],
			isModalOpen: false,
			ismultiSelectModal:false,
			userGroupId: "",
			fromGroupId:"",
			searchUsersText:"",
			searchResults:[],
			searchResultsMob:[],
			selectedUsers:[],
			alertOpen: false,
			successMessage:"",
			errorMessage:"",
			userRowsSelected: []
		}
	}

	fetchUserGroups = () => {
			$.ajax({
	    type: 'GET',
	    url: getBaseURL() + '/search-user-groups.json?limit=30',
    }).done((data)=>{
    	var userGroups=data;
    	this.setState({userGroups})
    })
	}
	populateAssociatedUsersFromResults(data){
		console.log(data);
		var associatedUsers= data["user_group_students"];
  	var userMappingTableData= [];
  	var userMappingTableDataMob=[];

		if(associatedUsers && associatedUsers.length > 0){
			for(var i in associatedUsers){
				userMappingTableData.push([
					associatedUsers[i].global_user_id,
					associatedUsers[i].username,
					associatedUsers[i].fullname,
					associatedUsers[i].branch,
					associatedUsers[i].program,
					associatedUsers[i].section
				]);
				userMappingTableDataMob.push([
					associatedUsers[i].global_user_id,
					associatedUsers[i].username,
					associatedUsers[i].fullname
				])
			}
			this.setState({userMappingTableData});
			this.setState({userMappingTableDataMob});
		}else{
			this.setState({userMappingTableData:[]});
			this.setState({userMappingTableDataMob:[]});
		}
	}

	fetchAssociatedUsers = (id) =>{
		$.ajax({
	    type: 'GET',
	    url: getBaseURL() + `/group-students?group_id=${id}&limit=100&offset=0`
    }).done((data)=>{
    	this.populateAssociatedUsersFromResults(data);
    })
	}

	handleOpenModal = (e) => {
		this.setState({isModalOpen: true});
	}

	handleCloseModal = (e) => {
		this.setState({isModalOpen: false});
	}

	handleToggleMultiSelectModal= (e) => {
		var isOpen=this.state.ismultiSelectModal;
		this.setState({searchUsersText:"",searchResults:[],selectedUsers:[],ismultiSelectModal: !isOpen});
	}

	handleChangeSearchInfo = (e) => {
		var searchUsersText = e.target.value;
		this.setState({searchUsersText: searchUsersText, selectedUsers: [], userRowsSelected: []});
		if(searchUsersText && searchUsersText.length > 3){ //Minimum three characters to search	    
	 		if(this.debounceTimer){
	  			clearTimeout(this.debounceTimer);
	  		}
	  		this.debounceTimer = setTimeout(()=>this.searchUsers(searchUsersText), 1000);
	 	} else{
	 		this.setState({searchResults: []});
	 	}
	}

	searchUsers(searchUsersText){		
	    $.ajax({
	      type: 'GET',
	      url: getBaseURL() + `/search_users.json?limit=10&offset=0&search=${searchUsersText}`,
	    }).done((data)=>{
	    	this.populateUsersFromResults(data);
	    }).fail((error) => {
	    		console.log("error: ",error);
	    })
	}

	populateUsersFromResults(data){
		
		var globalUsers= data;
  		var searchResults= [];
  		var searchResultsMob= [];

		if(globalUsers && globalUsers.length > 0){
			for(var i in globalUsers){
				searchResults.push([
					globalUsers[i].id,
					globalUsers[i].username,
					globalUsers[i].name
				]);
				searchResultsMob.push([
					globalUsers[i].id,
					globalUsers[i].username
				])
			}
			this.setState({searchResults});
			this.setState({searchResultsMob});
		}else{
			this.setState({searchResults: []});
		}
	}

	handleTransferUser=(e) =>{
		var toId=this.state.userGroupId;
		var fromId=this.state.fromGroupId;

		console.log(fromId, toId);


		const postData={from_group_id: fromId, to_group_id: toId};

		$.ajax({
	    type: 'POST',
	    url: getBaseURL() + `/group-transfer`,
	    data:postData
    }).done((data)=>{
    	this.fetchAssociatedUsers(toId);
    	this.handleCloseModal();
    })


	}

	handleAddMutlipleUsersToGroup = (e) => {
		var userGroupId=this.state.userGroupId;
		var selectedUsers=this.state.selectedUsers;

		var selectedUserId=selectedUsers[0][0];
		var userIds = selectedUsers.map((user)=>user[0]).join()
		
		const postData={group_id: userGroupId, user_id: userIds}

		$.ajax({
		    type: 'POST',
		    url: getBaseURL() + `/associate-group`,
		    data:postData
	    }).done((data)=>{
	    	this.fetchAssociatedUsers(userGroupId);
	    	this.handleToggleMultiSelectModal();
	    	if(data && data.message){
				this.setState({alertOpen: true, successMessage: data.message});
			}
	    }).fail((error)=>{
		    var errorMessage = "Something went wrong";
			if(error["responseJSON"] && error["responseJSON"].message){
				errorMessage = error["responseJSON"].message
			}
		    this.setState({alertOpen: true, errorMessage});
	    })
	}

	handleDelete =(id) => {
		const groupId=this.state.userGroupId;


		const postData= { group_id: groupId, user_id: id};

		$.ajax({
	    type: 'POST',
	    url: getBaseURL() + `/dissociate-group`,
	    data:postData
    }).done((data)=>{
    	this.fetchAssociatedUsers(groupId);
    })


	}


	Transition =(props) => {
  	return (<Slide direction="down" {...props} />);
	}

	componentDidMount(){
		const userGroupId= qs.parse(this.props.location.search).user_group_id;
		this.setState({userGroupId:userGroupId})
		this.fetchAssociatedUsers(userGroupId);
		this.fetchUserGroups();
	}

	handleCloseAlert = (event, reason) => {
	    if (reason === 'clickaway') {
	      return;
	    }
	    this.setState({successMessage:""});
	    this.setState({errorMessage:""})
	    this.setState({alertOpen: false});
	  };

	render(){

	const {classes}= this.props;
	const {searchUsersText, selectedUsers}=this.state;

	const titleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "20px",
		paddingLeft: "20px",
	};
	const subtitleStyle={
	    color: "rgb(34, 56, 69)",
		fontWeight: "600",
		fontSize: "18px",
		fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
		paddingBottom: "10px",
	};
	const tableTitleStyle={
		fontWeight: "600",
		fontSize: "18px",
		fontFamily: "open sans, Helvetica Neue, Helvetica, Arial, sans-serif",
		padding: "0px", margin: "0px",
		paddingTop: "10px",
	};

	const userMappingTableOptions = {
			filter: false,
			selectableRows: 'none',
			search: false,
			print: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,

	};

	const userMappingTableColumns = [
		{	name: "global_user_id",
			options:{
				display: false
			}
		},
		{name: "User ID"},
		{name: "User Name"},
		{name: "Program"},
		{name: "Branch"},
		{name: "Section"},
		{	
				name: " ",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}=tableMeta;
              	const id=rowData[0];
              return (
 
                <IconButton onClick={(e) => {this.handleDelete(id)}} fontSize="sm"><DeleteIcon /></IconButton>
            
              );
              }
          }
		}
		     
	];

	const userMappingTableMobColumns = [
		{	name: "global_user_id",
			options:{
				display: false
			}
		},
		{name: "User ID"},
		{name: "Name"},
		{	
				name: " ",
				options: {
              customBodyRender: (value, tableMeta, updateValue) => {
              	const {rowData}=tableMeta;
              	const id=rowData[0];
              return (
 
                <IconButton onClick={(e) => {this.handleDelete(id)}} fontSize="sm"><DeleteIcon /></IconButton>
            
              );
              }
          }
		}
		     
	];

	const searchTableOptions = {
			filter: false,
			search: false,
			print: false,
			sort: false,
			download: false,
			elevation: 0,
			viewColumns: false,
			responsive: 'standard',
			expandableRows: false,
			selectableRows:"multiple",
			rowsPerPage: 100,
			rowsSelected: this.state.userRowsSelected,
			onRowsDelete: (rowsDeleted, data, newTableData) => false,
			customToolbarSelect: () => {},
			onRowSelectionChange:(currentRowsSelected, allRowsSelected,rowsSelected) => {
				var users=[];
				var searchResults=this.state.searchResults;
				allRowsSelected.map((row) => {
					users.push(searchResults[row.index]);
				})
				this.setState({selectedUsers:users, userRowsSelected: rowsSelected});	
				},
			setRowProps: (row) => {
        return {
          style: {textAlign: 'center'}
        };
      },
   /*  customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
 	    	return (<div >
  			<p style={tableTitleStyle} >Search Results</p>
  			<p>Users Selected</p>
  			</div>);
 	    },*/
	};

	const searchTableColumns = [
		{name:"id",
			options:{
				display: false
			}
		},
		{name: "User ID",
			options:{
				customBodyRender: (value, updateValue) => {
					return(
						<div>
							{value}
						</div>
					);
				}
			}
		}, 
		{name: "Full Name",

			options:{
				customBodyRender: (value, updateValue) => {
					return(
						<div >
							{value}
						</div>
					);
				}
			}
		},
		
	];

	const searchTableMobColumns = [
		{name:"id",
			options:{
				display: false
			}
		},
		{name: "User ID",
			options:{
				customBodyRender: (value, updateValue) => {
					return(
						<div>
							{value}
						</div>
					);
				}
			}
		}
	];

	const formComponent =  (
				<React.Fragment>
			      	<GridContainer style={{
			      	backgroundColor: "white",
					paddingTop: "0px",
			      	width: "100%", 
			      	margin: "10px 0px 0px",
			      	marginTop: "0px"}}
			      	spacing={24} >
	      	
					<GridItem  xs={12} sm={12} md={12} style={{padding: "12px"}}>
						<form className={classes.form}>
      				<TextField variant="outlined" label="Group" select fullWidth required className={classes.inputLabel}
      				name="fromGroupId"
      				value={this.state.fromGroupId}
      				onChange={(e) => {this.setState({fromGroupId:e.target.value})}}>
							  {
							  	this.state.userGroups.map((group) => {
							  		return(<MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>);
							  	})
							  }
							</TextField>
						</form>
			  	</GridItem>	
         		 </GridContainer>
        </React.Fragment>		
    );
	
		return(

			<React.Fragment>
				<GridContainer style={{
	      		backgroundColor: "white",
						paddingTop: "0px",
	      		paddingBottom: "20px",
	      		width: "100%", 
	      		margin: "10px 0px 0px",
	      		marginTop: "0px"}}
	      		spacing={24}>
	      			{
		      			this.state.successMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="success" style={{marginTop: "50px"}}>
          						{this.state.successMessage}
        					</Alert>
		      			</Snackbar>
		      		}
	      			{
		      			this.state.errorMessage &&
		      			<Snackbar open={this.state.alertOpen} anchorOrigin={{vertical:'top', horizontal:'right'}} autoHideDuration={3000} onClose={this.handleCloseAlert}>
		      				 <Alert onClose={this.handleCloseAlert} severity="error" style={{marginTop: "50px"}} >
          						{this.state.errorMessage}
        					</Alert>
		      			</Snackbar>
		      		}
		      		<GridItem xs={12} sm={12} md={12} style={{paddingTop: "0px", paddingBottom: "0px"}}>
		      			<h1 style={titleStyle} >Manage User Mappings</h1>
		      			<hr/>
		      		</GridItem>
	
		      			<GridItem  style={{backgroundColor: "#ffffff"}}  xs={12} sm={12} md={12}
		      			 style={{display:"flex", justifyContent:"flex-end", alignItems:"center"}}>
		      				<Button onClick={this.handleToggleMultiSelectModal} style={{textTransform: "none", backgroundColor: "#3f51b5", 
			  					padding: "11px", margin: "12px 0px"}}>
									Add Multiple Users to Group</Button>

									<Button onClick={this.handleOpenModal} style={{textTransform: "none", backgroundColor: "#3f51b5", padding: "11px", margin:"0px 4px"}}>
									Add Existing User Group</Button>

			  				</GridItem>

		      		<Hidden smDown>
		      			<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none"}}>
		      				<MuiThemeProvider theme={getMuiThemeForTable}>
		      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Associated Users</p>}
			      			  	options={userMappingTableOptions}
			      			  	columns={userMappingTableColumns}  
			      			  	data={this.state.userMappingTableData}     
			      				/>
		      				</MuiThemeProvider>
	      				</GridItem>
		      		</Hidden>
		      		<Hidden mdUp>
		      			<GridItem xs={12} sm={12} md={12} style={{padding: "12px", boxShadow: "none"}}>
		      				<MuiThemeProvider theme={getMuiThemeForTable}>
		      					<MUIDataTable 
			      			  	title={<p style={tableTitleStyle} >Associated Users</p>}
			      			  	options={userMappingTableOptions}
			      			  	columns={userMappingTableMobColumns}  
			      			  	data={this.state.userMappingTableDataMob}     
			      				/>
		      				</MuiThemeProvider>
	      				</GridItem>
		      		</Hidden>
	      			<GridItem xs={12} sm={12} md={12} style={{padding: "12px"}}>
	      				<div>
						   	<MuiThemeProvider theme={getMuiThemeForModal}>
							    <Dialog
					          	classes={{	
					            root: classes.center,
					            paper: classes.modal
					          }}
					          open={this.state.isModalOpen}
					          TransitionComponent={this.Transition}
					          keepMounted
					          onClose={this.handleCloseModal}
					          aria-labelledby="modal-slide-title"
					          aria-describedby="modal-slide-description">
					          <DialogTitle
					            id="classic-modal-slide-title"
					            disableTypography
					            className={classes.modalHeader}>
					            <IconButton
					              className={classes.modalCloseButton}
					              key="close"
					              aria-label="Close"
					              color="inherit"
					              onClick={this.handleCloseModal}>
					              <Close className={classes.modalClose} />
					            </IconButton>
					            <h4 className={classes.modalTitle}>Add Existing User Group</h4>
					          </DialogTitle>
					          <DialogContent style={{paddingBottom: "0px", overflowY: "visible"}}
					            id="modal-slide-description"
					            className={classes.modalBody}>
					            {formComponent}
					          </DialogContent>
					          <DialogActions
					          style={{padding: "14px", paddingRight: "30px"}}
					            className={classes.modalFooter + " " + classes.modalFooterRight }>
					            <Button    
					              size="sm"
					              onClick={this.handleTransferUser}
					              className={classes.searchButton}>
					              Add Group
					            </Button>
					            <Button    
					              size="sm"
					              onClick={this.handleCloseModal}
					              className={classes.cancelButton}>
					              Cancel
					            </Button>
					          </DialogActions>
					        </Dialog>
						     </MuiThemeProvider>
						     <MuiThemeProvider theme={getMuiThemeForModal}>
							    <Dialog
					          	classes={{	
					            root: classes.center,
					            paper: classes.multiModal
					          }}
					          open={this.state.ismultiSelectModal}
					          TransitionComponent={this.Transition}
					          keepMounted
					          onClose={this.handleToggleMultiSelectModal}
					          aria-labelledby="modal-slide-title"
					          aria-describedby="modal-slide-description">
					          <DialogTitle
					            id="classic-modal-slide-title"
					            disableTypography
					            className={classes.modalHeader}>
					            <IconButton
					              className={classes.modalCloseButton}
					              key="close"
					              aria-label="Close"
					              color="inherit"
					              onClick={this.handleToggleMultiSelectModal}>
					              <Close className={classes.modalClose} />
					            </IconButton>
					            <h4 className={classes.modalTitle}>Add Multiple Users to Group</h4>
					          </DialogTitle>
					          <DialogContent style={{paddingBottom: "0px", overflowY: "visible"}}
					            id="modal-slide-description"
					            className={classes.modalBody}>
					            <React.Fragment>
						            <GridItem  xs={12} sm={12} md={12}>
									  			<form className={classes.form} onSubmit={e => e.preventDefault()}>
							      				<TextField variant="outlined" label="Search Users" fullWidth placeholder="Search Users" 
							      				className={classes.inputLabel} helperText="Please enter minimum 4 characters"
							      				name="searchUsersText"
							      				value={searchUsersText}
							      				onChange={this.handleChangeSearchInfo}
											      />
							            </form>
							         </GridItem>
							         {
							         	this.state.searchResults.length > 0 && 

								         	<GridItem xs={12} sm={12} md={12} style={{padding: "0px", boxShadow: "none"}}>
								         	<Hidden smDown>
							      				<MuiThemeProvider theme={getMuiThemeForTable}>
							      					<MUIDataTable 
								      			  	title={<p style={tableTitleStyle} >Users</p>}
								      			  	options={searchTableOptions}
								      			  	columns={searchTableColumns}  
								      			  	data={this.state.searchResults}     
								      				/>
						      					</MuiThemeProvider>
						      				</Hidden>
						      				<Hidden mdUp>
							      				<MuiThemeProvider theme={getMuiThemeForTable}>
							      					<MUIDataTable 
								      			  	title={<p style={tableTitleStyle} >Users</p>}
								      			  	options={searchTableOptions}
								      			  	columns={searchTableMobColumns}  
								      			  	data={this.state.searchResultsMob}     
								      				/>
						      				</MuiThemeProvider>
						      				</Hidden>
						      				<GridItem
						      				style={{padding: "14px", paddingRight: "30px"}}
					            		className={classes.modalFooter + " " + classes.modalFooterRight }>
						      				 <Button    
							              size="sm"
							              onClick={this.handleAddMutlipleUsersToGroup}
							              disabled={selectedUsers.length>0 ? false: true}
							              className={classes.searchButton}>
							              Add Users
							            </Button>
							            </GridItem>
					      				</GridItem>
							         }
							        </React.Fragment>
					          </DialogContent>
					          
					        </Dialog>
						     </MuiThemeProvider>
				    		</div>
      				</GridItem>
		      	</GridContainer>
			</React.Fragment>
		);
	}
}

export default withStyles(styles)(UserMapping);
